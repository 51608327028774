import * as React from 'react';
import { TextField, Button, FormGroup, FormControlLabel, Switch, InputLabel, Select, MenuItem } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';
import styles from './CameraForm.module.css';

interface Props {
    onCameraSettingComplete : () => void;
}

interface State {
    useCamera : boolean;
    useMicrophone : boolean;
    videoSources : fm.liveswitch.SourceInput[],
    currentVideoSource : fm.liveswitch.SourceInput | null | undefined,
    audioSources : fm.liveswitch.SourceInput[],
    currentAudioSource : fm.liveswitch.SourceInput | null | undefined,
};


export default class CameraForm extends React.Component<Props, State> {

    cameraInput : any;
    micInput : any;

    state : State = {
        useCamera: true,
        useMicrophone: true,
        videoSources : [],
        currentVideoSource : undefined,
        audioSources : [],
        currentAudioSource : undefined
    };

    onCameraChange = ()=>{
        MessageBus.Raise("onUseCameraChanged", this.cameraInput.checked);
        let updatedState = {
            useCamera : this.cameraInput.checked
        }
        this.setState(updatedState);
    }

    onMicChange = ()=>{
        MessageBus.Raise("onUseAudioChanged", this.micInput.checked);
        let updatedState = {
            useMicrophone : this.micInput.checked
        }
        this.setState(updatedState);
    }

    componentDidMount = () =>{

        MessageBus.AddListener("VideoSourceUpdate", this.onVideoSourceUpdate);
        MessageBus.AddListener("AudioSourceUpdate", this.onAudioSourceUpdate);

        MessageBus.Raise("onCameraAndMicInit",{});
    }

    componentWillUnmount = () => {
        MessageBus.RemoveListener("VideoSourceUpdate", this.onVideoSourceUpdate);
        MessageBus.RemoveListener("AudioSourceUpdate", this.onAudioSourceUpdate);
    }

    onVideoSourceUpdate = (data:any) => {
        
        if(data == null || data ==  undefined) return;

        let videoSources = data.videoSources;
        let currentVideoDevice = data.currentVideoDevice;

        if(videoSources == null || videoSources == undefined) return;

        let updatedState = {
            videoSources : videoSources,
            currentVideoSource : currentVideoDevice
        }

        this.setState(updatedState);
        
    }

    onAudioSourceUpdate = (data:any) => {
        if(data == null || data ==  undefined) return;

        let audioSources = data.audioSources;
        let currentAudioDevice = data.currentAudioDevice;

        if(audioSources == null || audioSources == undefined) return;

        let updatedState = {
            audioSources : audioSources,
            currentAudioSource : currentAudioDevice
        }

        this.setState(updatedState);
    }

    onCameraDeviceChange = (event : any) => {

        let deviceID = event.target.value;
        let deviceName = "";

        let i=0;
        for(i=0; i<this.state.videoSources.length; ++i){
            if(this.state.videoSources[i].getId()==deviceID){
                deviceName = this.state.videoSources[i].getName();
                this.setState({currentVideoSource: this.state.videoSources[i]});
                break;
            }
        }

        MessageBus.Raise("onCameraSourceChanged",{id:deviceID, name:deviceName});

    }

    onAudioDeviceChange = (event : any) => {

        let deviceID = event.target.value;
        let deviceName = "";

        let i=0;
        for(i=0; i<this.state.audioSources.length; ++i){
            if(this.state.audioSources[i].getId()==deviceID){
                deviceName = this.state.audioSources[i].getName();
                this.setState({currentAudioSource: this.state.audioSources[i]});
                break;
            }
        }

        MessageBus.Raise("onAudioSourceChanged",{id:deviceID, name:deviceName});

    }


    render() {

        let currentVideoDeviceID : string = "";
        if(this.state && this.state.currentVideoSource != undefined){
            currentVideoDeviceID = this.state.currentVideoSource.getId();
        };
      
        let cameraInputMenuItems : JSX.Element[] = [];
        if(this.state.videoSources && this.state.videoSources.length > 0){
            cameraInputMenuItems = this.state.videoSources.map(    (vidSource : fm.liveswitch.SourceInput)=>{
                                        return <MenuItem key={vidSource.getId()} value={vidSource.getId()}>{vidSource.getName()}</MenuItem>;
                                    });
        }


        let cameraSelect = (    <FormControlLabel style={{ marginLeft: '0px'}}
                                    control={   <Select labelId="demo-simple-select-label"  id="demo-simple-select" value={currentVideoDeviceID} onChange={this.onCameraDeviceChange}>                                                   
                                                    {cameraInputMenuItems}
                                                </Select>}
                                    label="Camera Source"
                                />
                            );


        let currentAudioDeviceID : string = "";
        if(this.state && this.state.currentAudioSource != undefined){
            currentAudioDeviceID = this.state.currentAudioSource.getId();
        };  

        let audioInputMenuItems : JSX.Element[] = [];
        if(this.state.audioSources && this.state.audioSources.length > 0){
            audioInputMenuItems = this.state.audioSources.map(    (audSource : fm.liveswitch.SourceInput)=>{
                                        return <MenuItem key={audSource.getId()} value={audSource.getId()}>{audSource.getName()}</MenuItem>;
                                    });
        }


        let audioSelect = (    <FormControlLabel style={{ marginLeft: '0px'}}
                                    control={   <Select labelId="demo-simple-select-label"  id="demo-simple-select" value={currentAudioDeviceID} onChange={this.onAudioDeviceChange}>                                                   
                                                    {audioInputMenuItems}
                                                </Select>}
                                    label="Audio Source"
                                />
                            );
                        

        
        return (
            
            <div className="camera-settings">
                <h1 className="heading">Camera Settings</h1>
                <p className={styles.subHeadP}>Keep your camera and mic on if you want to talk to other attendees.</p>
                <FormGroup row>
                    <FormControlLabel
                        control={<Switch inputRef={el => this.cameraInput = el} checked={this.state.useCamera} onChange={this.onCameraChange} name="useCamera" />}
                        label="Camera"
                    />
                </FormGroup>

                <FormGroup row>
                    <FormControlLabel
                        control={<Switch inputRef={el => this.micInput = el} checked={this.state.useMicrophone} onChange={this.onMicChange} name="useMicrophone" />}
                        label="Microphone"
                    />
                </FormGroup>

                <h1 className="heading" style={{ marginTop: '20px'}}>Choose a camera and microphone</h1>
                <p className={styles.subHeadP}>Select a camera and microphone from those available on your system.</p>

                <FormGroup row>
                    {cameraSelect}                    
                </FormGroup>

                <FormGroup row>
                    {audioSelect}                    
                </FormGroup>



                



                
                <Button variant="contained" color="primary" onClick={this.props.onCameraSettingComplete} style={{ marginTop: '20px'}} >
                    FINISH
                </Button>
            </div>
            
        )
    }

}












