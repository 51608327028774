
import 'fm.liveswitch';
import { LocalMediaController } from './LocalMediaController';
import { LocalStreamUpController } from './LocalStreamUpController';
import { RemoteLobbyController } from './RemoteLobbyController';
import {LobbyDefaultConfig} from '../utilities/LobbyConfig';


export class LiveSwitchController{

    public localMediaController : LocalMediaController
    public localStreamUpController : LocalStreamUpController
    public remoteLobbyController : RemoteLobbyController

    private applicationID : string = 'dc68fa69-6578-4b42-a0ae-a567b850a1d7';
    private secret : string = '284d283ec0124367a04508975c3ad7072929eb6299444c73b0c08b38dd61e10b';

    private lobbyChannelName : string = LobbyDefaultConfig.MediaChannel;
    private lobbyChannel : fm.liveswitch.Channel;

    private deviceID : string; //This is the computers ID
    private client : fm.liveswitch.Client;


    private unRegistering : boolean = false;
    private reRegisterBackoff = 200;
    private maxRegisterBackoff = 60000;

    private foo : number;

    constructor(public userID : string){
        this.deviceID = LiveSwitchController.generateUUID();   
        
        //Create our livestream client
        this.client = new fm.liveswitch.Client("https://cloud.liveswitch.io/", this.applicationID, this.userID, this.deviceID, "babylonClient");
        this.client.setAutoUnregister(true);

        //Create our media controller
        this.localMediaController = new LocalMediaController();

        window.addEventListener('beforeunload', (event) => {
            this.disconnect();
        });


        //Subscribe for state changes
        /*
        //TODO TODO TODO Plan backoff sytrategy for logging back in

        this.client.addOnStateChange((client) => {
            if (client.getState() == fm.liveswitch.ClientState.Registering) {
                fm.liveswitch.Log.debug("Client is registering");
            }
            else if (client.getState() == fm.liveswitch.ClientState.Registered) {
                fm.liveswitch.Log.debug("Client is registered");
            }
            else if (client.getState() == fm.liveswitch.ClientState.Unregistering) {
                fm.liveswitch.Log.debug("Client is unregistering");
            }
            else if (client.getState() == fm.liveswitch.ClientState.Unregistered) {
                fm.liveswitch.Log.debug("Client is unregistered");
                // Client has failed for some reason:
                
                if (!this.unRegistering) {
                    
                    setTimeout(() => {
                        // Back off our reregister attempts as they continue to fail to avoid runaway process.
                        if (this.reRegisterBackoff < this.maxRegisterBackoff) {
                            this.reRegisterBackoff += this.reRegisterBackoff;
                        }
                        // ReRegister
                        let _token = this.generateToken([]);
                        this.client.register(token).then((channels) => {
                            this.reRegisterBackoff = 200; // reset for next time
                            
                        }, (ex) => {
                            fm.liveswitch.Log.error("Failed to register with Gateway.");
                            promise.reject(ex);
                        });
                    }, _this.reRegisterBackoff);
                }
            }
        });
        */

    }

    public connect(useAudio:boolean, useVideo:boolean) : fm.liveswitch.Future<boolean>{

        let promise = new fm.liveswitch.Promise<boolean>();

        this.register().then(()=>{

            console.log("Liveswitch registration successful");

            this.joinChannel(this.lobbyChannelName).then((channel)=>{

                console.log("Succesfully joined channel: " + this.lobbyChannelName);
                this.lobbyChannel = channel;

                //Begin our local liveswitch
                this.localStreamUpController = new LocalStreamUpController(this.client, this.lobbyChannel);   
                this.localStreamUpController.connect(this.localMediaController, useAudio, useVideo)
                .then(()=>{
                    //we connected our upstream channel;
                })
                .fail((ex)=>{
                    console.log("Something failed connecting our upstream channel");
                    console.log(ex);
                });


                //Begin our remote
                this.remoteLobbyController = new RemoteLobbyController(this.lobbyChannel);                
                

                promise.resolve(true);
            }).fail((exception)=>{
                console.log(exception);
                console.log("Failed to join channel: " + this.lobbyChannel);
            });


        }).fail((exception)=>{
            console.log(exception);
            console.log("Liveswitch registration failure");
            promise.reject(exception);
        });
                
        return promise;
        
    }


    disconnect(){
        this.unRegistering = true;     
        
        /*
        
        this.client.unregister().then(()=>{
            console.log("Succesfully unregistered")
        }).fail( (ex) => {
            console.log("Failed to unregister");
        });
        */

    }


    /******************************************************************
     * 
     * 
     * HELPERS
     * 
     * 
     ******************************************************************/


    private register() : fm.liveswitch.Future<fm.liveswitch.Channel[]>   {
        
        //Generate a token and register the client with the server
        let token = this.generateToken([]); //create a token with no channel subscriptions
        
        return this.client.register(token);
    }


    private joinChannel(channelName : string) : fm.liveswitch.Future<fm.liveswitch.Channel>{   
            let token : string = this.generateToken([channelName]);   
            return this.client.join(channelName, token);
    }

    private leaveChannel(channelId : string ){
        this.client.leave(channelId).then(function(channel) {
            console.log("left the channel");
        }).fail(function(ex) {
            console.log("failed to leave the channel");
        });
    }


    private generateToken(channels : string[]) : string {

        let fmChannels : fm.liveswitch.ChannelClaim[] = [];
        channels.forEach((c)=>{
            fmChannels.push(new fm.liveswitch.ChannelClaim(c));
        });

        let token = fm.liveswitch.Token.generateClientRegisterToken(
            this.applicationID,
            this.client.getUserId(),
            this.client.getDeviceId(),
            this.client.getId(),
            this.client.getRoles(),
            fmChannels,
            this.secret
        );

        return token;
    }

    static generateUUID() : string { 
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

}