import 'fm.liveswitch';
import { iAvatarMediaController } from "../avatars/iAvatarMediaController";

export class RemoteStreamController implements iAvatarMediaController {

    public userID : string;

    private onConnectionLost : (remoteStreamController:RemoteStreamController) => void;
    private remoteMedia : fm.liveswitch.RemoteMedia;
    private audioStream : fm.liveswitch.AudioStream;
    private videoStream :  fm.liveswitch.VideoStream;
    private connection : fm.liveswitch.SfuDownstreamConnection;
    private htmlVideoElement : HTMLVideoElement;


    private connectionHadAudio : boolean;
    private connectionHasVideo : boolean;
    private connectionConfig : fm.liveswitch.ConnectionConfig;

    constructor(private channel : fm.liveswitch.Channel, private connectionInfo : fm.liveswitch.ConnectionInfo ){
        this.userID = connectionInfo.getUserId();

        this.connectionHadAudio = connectionInfo.getHasAudio();
        this.connectionHasVideo = connectionInfo.getHasVideo();
    }

    public connect(onConnectionLost : (remoteStreamController:RemoteStreamController) => void) : fm.liveswitch.Future<boolean>{

        this.onConnectionLost = onConnectionLost;
        let promise = new fm.liveswitch.Promise<boolean>();

        this.remoteMedia = new fm.liveswitch.RemoteMedia();

        if(this.connectionInfo.getHasAudio()){
            this.audioStream = new fm.liveswitch.AudioStream(this.remoteMedia);
        }

        if(this.connectionInfo.getHasVideo()){
            this.videoStream = new fm.liveswitch.VideoStream(this.remoteMedia);
        }

        if(this.connectionInfo.getHasAudio() && this.connectionInfo.getHasVideo()){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.audioStream, this.videoStream);
        } else if(this.connectionInfo.getHasAudio() ){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.audioStream);
        } else if(this.connectionInfo.getHasVideo() ){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.videoStream);
        }

        this.connection.open().then(()=>{
            this.htmlVideoElement = this.remoteMedia.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;
            this.connection.addOnStateChange( (c : fm.liveswitch.ManagedConnection)=>{this.onConnectionStateChange(c)});

            this.connectionConfig = this.connection.getConfig();

            //testing foo
           // setInterval(()=>{
            //    console.log("Bandwidth: " + this.videoStream.getRemoteBandwidth() + " -- " + this.videoStream.getLocalBandwidth());
            //},250);


            promise.resolve(true);  
        }).fail((ex)=>{
            console.log(ex);
            console.log("Streaming connection failed to new remote connection");            
            promise.reject(ex);
        }); 

        return promise;
    }


    onConnectionStateChange(c : fm.liveswitch.ManagedConnection){
        if (c.getState() == fm.liveswitch.ConnectionState.Closing || c.getState() == fm.liveswitch.ConnectionState.Failing) {
            console.log("Connection lost: " + this.userID);
            this.onConnectionLost(this);
        }
    }

 

    /***************************************************************************************************
     * 
     * 
     * interface iAvatarMediaController
     * 
     * 
     ***************************************************************************************************/

    setVolume(level: number): void {

        if(!this.audioStream) return;

        if(level <= 0){
            this.audioStream.setMuted(false);
        } else {
            //Unmute if we are muted
            if(this.audioStream?.getMuted()){
                this.audioStream.setMuted(false);                            
            }
            this.remoteMedia.getAudioTrack().setVolume(level);    
        }
    }

    playAudio(): void {
        
        //this.audioStream.setMuted(false);
        this.remoteMedia.setAudioMuted(false);
    }

    stopAudio(): void {
        //this.audioStream.setMuted(true);
        this.remoteMedia.setAudioMuted(true);
    }

    playVideo(): void {
        //this.videoStream.setMuted(false);
        //this.videoStream.setLocalReceive(true);
        this.remoteMedia.setVideoMuted(false);

        //console.log("PLAYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY");

        //console.log(this.connectionConfig);

        /*
        this.connection.update(this.connectionConfig).then(()=>{
            console.log("Update");
        }).fail((ex)=>{
            console.log("Update fail");
            console.log(ex);
        });
        */

        //this.connection.updateConnection(this.connection.getRemoteConnectionInfo(), this.connectionInfo);

        
        
        

        //console.log("ZZZZ");
        
        //console.log("D: " + this.videoStream.getDirection());
    }

    stopVideo(): void {
        //console.log("MUTING VIDEO STREAM");
        //console.log("A" + this.videoStream.getDirection());
        //this.videoStream.setMuted(true);
        //this.videoStream.setLocalReceive(false);
        //this.connection.setRemo
        this.remoteMedia.setVideoMuted(true);
        //console.log("B: " + this.videoStream.getDirection());
        //this.videoStream.setLocalDirection(fm.liveswitch.StreamDirection.SendOnly);

       /*
        console.log(this.connectionConfig);
        
        let newConnectionConfig : fm.liveswitch.ConnectionConfig = new fm.liveswitch.ConnectionConfig();        
        //newConnectionConfig.setAudioDirection("inactive");
        newConnectionConfig.setVideoDirection("inactive");
        //newConnectionConfig.setLocalAudioDisabled(true);
        //newConnectionConfig.setLocalAudioMuted(true);
        //newConnectionConfig.setLocalVideoDisabled(true);
        //newConnectionConfig.setLocalVideoMuted(true);
        //newConnectionConfig.setRemoteAudioDisabled(true);
        //newConnectionConfig.setRemoteVideoDisabled(true);

       //
       
       
        this.connection.update(newConnectionConfig).then(()=>{
            console.log("Update complete");
        }).fail((ex)=>{
            console.log("Update fail");
            console.log(ex);
        });
        */
        
        //console.log("C: " + this.videoStream.getDirection());
        //this.connection.close();
        
    }

    getHTMLVideoElement(): HTMLVideoElement {
        return this.htmlVideoElement;
    }


}