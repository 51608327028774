import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import { PBRMaterial, Vector3, Mesh, MeshStageAction, Texture, KeyboardInfo, Quaternion } from 'babylonjs';
import { pipeline } from 'stream';
import { JumbotronController } from '../controllers/JumbotronController';
import SocketIOController, { SocketIOSubscriber } from './SocketIOController';
import { TeleportController } from '../controllers/TeleportController';


export class LobbyStage{

    //Class properties
    mainSceneModelTask : BABYLON.MeshAssetTask;
    sky : BABYLON.MeshAssetTask;
    feetTextureTask : BABYLON.TextureAssetTask;
    feetGraphic : BABYLON.Mesh;

    //TEMP
    GEModelTask : BABYLON.MeshAssetTask;

    groundMesh : BABYLON.Mesh;
    reflectiveCircleMesh : BABYLON.Mesh;
    reflectionMeshes : BABYLON.Mesh[] = [];
    reflectMaterial : PBRMaterial;
    animatedTexture : BABYLON.Texture;

    feet : BABYLON.Mesh;
    isAnimating : boolean = false;
    canWalk : boolean = false;
    TWO_PI = Math.PI * 2;

    walkMusicTask : BABYLON.BinaryFileAssetTask;
    walkSound : BABYLON.Sound;

    jumbotronController : JumbotronController;

    elapsedTimeSinceSlideChange : number = 0;

    constructor(private engine:BABYLON.Engine, private scene : BABYLON.Scene, private camera : BABYLON.UniversalCamera, private socketIOController : SocketIOController){

    }

    

    load = () : Promise<boolean> => {

        //Neon
        
        return new Promise<boolean>((resolve,reject)=>{        
            
            let assetsManager = new BABYLON.AssetsManager(this.scene);

            this.mainSceneModelTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "HubArea6-8B.glb");
            this.feetTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/walk.png");

            //this.walkMusicTask = assetsManager.addBinaryFileTask("walkMusicTask", "./assets/sounds/Vibrant Slick Click.mp3");

            //TEMP loading GE stuff
            this.GEModelTask = assetsManager.addMeshTask("GETask", "", "./assets/models/", "Premier+BG6-9B.glb");

            assetsManager.onFinish = (tasks) => {
               
                //Sound
                //this.walkSound = new BABYLON.Sound("walkSound", this.walkMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });


                let meshes = this.mainSceneModelTask.loadedMeshes;
                if(meshes != null){
                    //Locate the ground, path, and screen
                    for(var i=0;i<meshes.length;i++) {
                        meshes[i].checkCollisions = true;
                        
                        if(meshes[i].name == "__root__")
                        {
                            meshes[i].scaling = new BABYLON.Vector3(1.5, 1.5, -1.5);
                            //meshes[i].rotationQuaternion = Quaternion.Identity();
                        }

                        if(meshes[i].name == "ReflectiveCircle"){
                            this.reflectiveCircleMesh = meshes[i] as BABYLON.Mesh;

                            this.reflectMaterial = new BABYLON.PBRMaterial("floorMaterial", this.scene);
                            this.reflectMaterial.metallic = 0;
                            this.reflectMaterial.roughness = 0;

                            this.reflectiveCircleMesh.material?.dispose(false, true);
                            this.reflectiveCircleMesh.material = this.reflectMaterial;
                        }
                        
                        if(meshes[i].name == "Screen_primitive0"){
                            meshes[i].scaling.x = -1;

                            let screenMaterial = new BABYLON.PBRMaterial("jumbotronMaterial", this.scene);
                            screenMaterial.unlit = true;
                            meshes[i].material?.dispose();
                            meshes[i].material = screenMaterial;

                            this.jumbotronController = new JumbotronController(this.socketIOController, screenMaterial, this.scene);

                            this.scene.registerBeforeRender(() => {
                                this.elapsedTimeSinceSlideChange += this.engine.getDeltaTime();
                            });

                            this.scene.onKeyboardObservable.add((kbInfo) => {
                                
                                switch(kbInfo.type){
                                    case BABYLON.KeyboardEventTypes.KEYDOWN:
                                        //throttle sending too many requests
                                        if(this.elapsedTimeSinceSlideChange > 250){
                                            if(kbInfo.event.key == "0"){
                                                this.elapsedTimeSinceSlideChange = 0;
                                                this.socketIOController.SetServerVariable("jumboTronMsg", {slideNumber:0});
                                            }
                                            else if(kbInfo.event.key == "1"){
                                                this.elapsedTimeSinceSlideChange = 0;
                                                this.socketIOController.SetServerVariable("jumboTronMsg", {slideNumber:1});
                                            }
                                            else if(kbInfo.event.key == "2"){
                                                this.elapsedTimeSinceSlideChange = 0;
                                                this.socketIOController.SetServerVariable("jumboTronMsg", {slideNumber:2});
                                            }
                                        }

                                    break;    
                                }
                            });
                        }

                        if(meshes[i].name == "RampColliders"){
                            meshes[i].material?.dispose();
                            meshes[i].visibility = 0;
                        }

                        if(meshes[i].name == "FloorCircle"){
                            this.animatedTexture = meshes[i].material?.getActiveTextures()[0] as Texture;
                        }

                        if(meshes[i].name == "Floor"){
                            this.groundMesh = meshes[i] as BABYLON.Mesh;
                            this.groundMesh.actionManager = new BABYLON.ActionManager(this.scene);
                        } 

                        if(meshes[i].name == "Path_primitive0"){
                            this.reflectionMeshes.push(meshes[i] as Mesh);
                        }

                        if(meshes[i].name == "Path_primitive2"){
                            this.reflectionMeshes.push(meshes[i] as Mesh);
                        }

                        if(meshes[i].name == "Triangles_primitive0"){
                            this.reflectionMeshes.push(meshes[i] as Mesh);
                            meshes[i].checkCollisions = false;
                        }

                        if(meshes[i].name == "Triangles_primitive1"){
                            this.reflectionMeshes.push(meshes[i] as Mesh);
                            meshes[i].checkCollisions = false;
                        }

                        if(meshes[i].name == "Screen_primitive0"){
                            this.reflectionMeshes.push(meshes[i] as Mesh);
                        }


                        

                    }


                    //Setup mirror texture
                    this.reflectiveCircleMesh.computeWorldMatrix(true);
                    let mirror_worldMatrix = this.reflectiveCircleMesh.getWorldMatrix();

                    let mirror_vertexData = this.reflectiveCircleMesh.getVerticesData("normal");    
                    let mirror_normal = new BABYLON.Vector3(0,0,0);

                    if(mirror_vertexData != null){
                        mirror_normal = new BABYLON.Vector3(mirror_vertexData[0], mirror_vertexData[1], mirror_vertexData[2]);
                    }

                    mirror_normal = BABYLON.Vector3.TransformNormal(mirror_normal, mirror_worldMatrix);

                    let reflector = BABYLON.Plane.FromPositionAndNormal(this.reflectiveCircleMesh.position, mirror_normal.scale(-1));

                    let reflectTexture = new BABYLON.MirrorTexture("mirror", 512, this.scene, true);
                    reflectTexture.mirrorPlane = reflector;
                    reflectTexture.adaptiveBlurKernel = 32;
                    reflectTexture.renderList = this.reflectionMeshes;

                    this.reflectMaterial.reflectionTexture = reflectTexture;

                    let uOffsetAnimation = new BABYLON.Animation("uOffsetAnimation", "uOffset", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

                    let keys = [];
                    keys.push({
                        frame: 0,
                        value: 0
                    });

                    keys.push({
                        frame: 360,
                        value: 1
                    });

                    uOffsetAnimation.setKeys(keys);

                    this.animatedTexture.animations = [];
                    this.animatedTexture.animations.push(uOffsetAnimation);
                    this.scene.beginAnimation(this.animatedTexture, 0, 360, true);
                }





                //Basic Camera movement and Physics
                this.camera.checkCollisions = true;
                this.camera.applyGravity = true;
                this.camera.ellipsoid = new BABYLON.Vector3(0.2, 1, 0.2);
                this.camera.position.z = 7;
                this.camera.maxZ = 500;

                this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 1);

                this.scene.gravity = new BABYLON.Vector3(0, -0.3, 0);    
                this.scene.collisionsEnabled = true;

                //this.EnableWalkAround();

                /*
                var pipeline = new BABYLON.DefaultRenderingPipeline(
                    "LobbyStagePipeline",
                    true,
                    this.scene,
                    [this.camera]
                );
                */

                //Load GE Area
                let geMeshes = this.GEModelTask.loadedMeshes;
                if(geMeshes != null){
                    //Locate the ground, path, and screen
                    for(var i=0;i<geMeshes.length;i++) {
                        geMeshes[i].checkCollisions = true;

                        if(geMeshes[i].name == "__root__"){
                            geMeshes[i].scaling = new BABYLON.Vector3(1.6, 1.6, -1.6);
                            geMeshes[i].setAbsolutePosition(new BABYLON.Vector3(0, 1000, 0));
                        }

                        if(geMeshes[i].name == "Floor"){
                            this.groundMesh = geMeshes[i] as BABYLON.Mesh;
                            this.groundMesh.actionManager = new BABYLON.ActionManager(this.scene);
                        } 
                    }
                }


                let teleportController = new TeleportController(this.engine,this.scene,this.camera,this.socketIOController);

                resolve();
               
            };
            assetsManager.load();

            


        });


        //GE
        /*
        return new Promise<boolean>((resolve,reject)=>{        
            
            let assetsManager = new BABYLON.AssetsManager(this.scene);

            this.mainSceneModelTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "Premier+BG5-27.glb");
            this.feetTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/walk.png");

            //this.walkMusicTask = assetsManager.addBinaryFileTask("walkMusicTask", "./assets/sounds/Vibrant Slick Click.mp3");


            assetsManager.onFinish = (tasks) => {
               
                //Sound
                //this.walkSound = new BABYLON.Sound("walkSound", this.walkMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });
             
                let meshes = this.mainSceneModelTask.loadedMeshes;
                if(meshes != null){
                    //Locate the ground, path, and screen
                    for(var i=0;i<meshes.length;i++) {
                        meshes[i].checkCollisions = true;

                        if(meshes[i].name == "__root__"){
                            meshes[i].scaling = new BABYLON.Vector3(1.6, 1.6, -1.6);
                        }

                        if(meshes[i].name == "Floor"){
                            this.groundMesh = meshes[i] as BABYLON.Mesh;
                            this.groundMesh.actionManager = new BABYLON.ActionManager(this.scene);
                        } 
                    }
                }


                //Basic Camera movement and Physics
                this.camera.checkCollisions = true;
                this.camera.applyGravity = true;
                this.camera.ellipsoid = new BABYLON.Vector3(2, 1, 2);

                this.scene.clearColor = new BABYLON.Color4(0.01,0.01,0.01,1);

                this.scene.gravity = new BABYLON.Vector3(0, -0.9, 0);    
                this.scene.collisionsEnabled = true;

                //this.EnableWalkAround();

             
                resolve();
               
            };
            assetsManager.load();

            


        });
        */
    }

    
    
    

    public EnableWalkAround (){

        //Create feet cursor
        this.feet =  BABYLON.Mesh.CreateBox("Feet", 1, this.scene);
        this.feet.isPickable = false;
        this.feet.rotationQuaternion = null;
        this.feet.isVisible = false;

        this.feetGraphic = BABYLON.Mesh.CreatePlane("FeetGraphic", 1, this.scene);
        this.feetGraphic.parent = this.feet;
        this.feetGraphic.position = new BABYLON.Vector3(0, 0, 0);
        this.feetGraphic.isPickable = false;

        let feetMaterial : BABYLON.StandardMaterial = new BABYLON.StandardMaterial("impactMat",this.scene);
        feetMaterial.diffuseTexture = this.feetTextureTask.texture;
        feetMaterial.diffuseTexture.hasAlpha = true;
        feetMaterial.emissiveColor = BABYLON.Color3.White();            
        this.feetGraphic.material = feetMaterial;            
        
        this.feetGraphic.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);


        this.canWalk = true;

        if(this.groundMesh != null && this.groundMesh.actionManager!= null){
            this.groundMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{     

                //BABYLON.Engine.audioEngine.unlock();
            
                let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
            
                if (pickResult && pickResult.hit) {
                    if(pickResult.pickedPoint != null){
                        if(!this.isAnimating){

                            this.isAnimating = true;

                            let allowedPosition = this.getFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z);

                            //Position
                            let fromPosition = new BABYLON.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z);
                            let toPosition = new BABYLON.Vector3(allowedPosition.x, this.camera.position.y, allowedPosition.y);    
                            //let toPosition = new BABYLON.Vector3(pickResult.pickedPoint.x, this.camera.position.y, pickResult.pickedPoint.z);    

                            BABYLON.Animation.CreateAndStartAnimation('cameraPositionAnimation', this.camera, 'position', 30, 30, fromPosition, toPosition, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, undefined, ()=>{
                                this.isAnimating=false;    
                            });
                           

                            //Animate rotation
                            let angle : number = Math.atan2( toPosition.x - fromPosition.x, this.feet.position.z - fromPosition.z);    
                           
                            //console.log("Angle " + angle);
                            let delta = angle - this.camera.rotation.y;
                            //console.log("camera " + this.camera.rotation.y);
                            //console.log("delta " + delta);

                            //Limit rotation to 180 degrees max
                            while(Math.abs(delta) > Math.PI){
                                if(delta > 0){
                                    angle -= this.TWO_PI;                                    
                                } else {
                                    angle += this.TWO_PI;
                                }
                                delta = angle - this.camera.rotation.y;
                            }
                            
                            BABYLON.Animation.CreateAndStartAnimation('cameraRotationAnimation', this.camera, 'rotation.y', 30, 30, this.camera.rotation.y, angle, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

                            this.updateFeetPosition(toPosition.x, toPosition.z, angle);

                            //this.walkSound.play();

                        }
                    }
                }
            }));


            this.scene.onPointerObservable.add(this.onPointerObservable);

        }
            
    }


    getFeetPosition = (x : number, z:number) : BABYLON.Vector2 =>{

        let distanceOffWall = 1;

        let from = new BABYLON.Vector3(this.camera.position.x, 0.05,this.camera.position.z);
        let to = new BABYLON.Vector3(x, 0.05,z);

        let from2D = new BABYLON.Vector2(this.camera.position.x, this.camera.position.z);
        let to2D = new BABYLON.Vector2(x, z);

        let requestedDistance = BABYLON.Vector2.Distance(from2D, to2D);

        //Find the nearest collision
        let rayDirection = to.subtract(from);
        rayDirection = BABYLON.Vector3.Normalize(rayDirection);

        let ray = new BABYLON.Ray(from, rayDirection, 9999);
        let hit = this.scene.pickWithRay(ray);
        if (hit && hit.hit && hit.pickedPoint != null) {
            
            let hit2d = new  BABYLON.Vector2(hit.pickedPoint.x, hit.pickedPoint.z);
            let hitDistance = BABYLON.Vector2.Distance(from2D, hit2d);

            let maxAllowedDistance = hitDistance - distanceOffWall;
           
            //Check if we are too close to a wall
            if(hitDistance < distanceOffWall || requestedDistance > maxAllowedDistance){
                let allowDistance = hitDistance - distanceOffWall;    

                let deltaVector = rayDirection.multiply(new BABYLON.Vector3(allowDistance,0,allowDistance));
                let adjustedFootPosition = from.add(deltaVector);
                return new BABYLON.Vector2(adjustedFootPosition.x, adjustedFootPosition.z);

            } else {
                return new BABYLON.Vector2(x, z);
            }


        } else {
            return new BABYLON.Vector2(x, z);
        }

    }

   

    onPointerObservable = (pointerInfo : BABYLON.PointerInfo) =>{
        
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
                //console.log("POINTER DOWN");
                break;
            case BABYLON.PointerEventTypes.POINTERUP:
                //console.log("POINTER UP");
                break;
            case BABYLON.PointerEventTypes.POINTERMOVE:
                //console.log("POINTER MOVE");
                //console.log(this.scene);
                
                let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

        
                if (pickResult && pickResult.hit) {

                    if(pickResult.pickedMesh == this.groundMesh){
                        this.feetGraphic.isVisible = true;
                    } else {
                        this.feetGraphic.isVisible = false;
                    }

                    if(pickResult.pickedPoint != null){                        
                        let angle : number = Math.atan2( this.feet.position.x - this.camera.position.x, this.feet.position.z - this.camera.position.z);                        
                        this.feet.rotation.y = angle;   


                        let feetPosition = this.getFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z);
                        this.updateFeetPosition(feetPosition.x,feetPosition.y, angle);
                        
                        //this.updateFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z, angle);
                    }
                }
                

                break;
            case BABYLON.PointerEventTypes.POINTERWHEEL:
                //console.log("POINTER WHEEL");
                break;
            case BABYLON.PointerEventTypes.POINTERPICK:
                //console.log("POINTER PICK");
                break;
            case BABYLON.PointerEventTypes.POINTERTAP:
                //console.log("POINTER TAP");
                

                break;
            case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
                //console.log("POINTER DOUBLE-TAP");




                break;
        }
    }

    updateFeetPosition = (x : number, z : number, angle : number) => {

        this.feet.position.x = x;
        this.feet.position.y = 0.02;
        this.feet.position.z = z;

        this.feet.rotation.y = angle;

    }


}

