import * as BABYLON from 'babylonjs';
import SocketIOController from '../scene/SocketIOController';
import { Vector3, MeshBuilder, TransformNode } from 'babylonjs';


export class LocalAvatarController{

    public anchor : TransformNode;

    public volumeBubble : BABYLON.Mesh;
    public maxVolumeBubble : BABYLON.Mesh;
    public videoBubble : BABYLON.Mesh;
 

    private elapsedTimeSinceUpdate : number = 0;
    private serverUpdateRate : number = 1000 / 15;
    private maxTimeBetweenUpdates = 500;
    private elapsedTimeSinceDataStoreRequest : number = 0;
    private maxTimeBetweenDataStorage = 1000;
    
    volumeRadius : number = 15;
    maxVolumeRadius : number = 5;
    videoRadius : number = 20;

    lastPosition : BABYLON.Vector3 = new BABYLON.Vector3(-99,-99,-99);
    lastRotation : BABYLON.Vector3 = new BABYLON.Vector3(-99,-99,-99);

    constructor(private engine : BABYLON.Engine, private scene : BABYLON.Scene, private camera : BABYLON.UniversalCamera, private socketIOController : SocketIOController){

        this.scene.registerBeforeRender(this.updatePositionOnServer);

        this.anchor = new BABYLON.TransformNode("LocalAvatar",this.scene);
        this.anchor.parent = this.camera;

        console.log("this.videoRadius: " + this.videoRadius);

        this.volumeBubble = MeshBuilder.CreateSphere("VolumeBubble",{segments:128, diameter: this.volumeRadius*2}, this.scene);        
        this.maxVolumeBubble = MeshBuilder.CreateSphere("MaxVolumeBubble",{segments:128, diameter: this.maxVolumeRadius*2}, this.scene);
        this.videoBubble = MeshBuilder.CreateSphere("VideoBubble",{segments:128, diameter: this.videoRadius*2}, this.scene);

        /*
        let videoBubble_mat = new BABYLON.StandardMaterial("test Bubble", this.scene);
        videoBubble_mat.emissiveColor = new BABYLON.Color3(0,0,1);
        videoBubble_mat.alpha = 0.25;

        let maxVolume_mat = new BABYLON.StandardMaterial("test Bubble", this.scene);
        maxVolume_mat.emissiveColor = new BABYLON.Color3(0,1,0);
        maxVolume_mat.alpha = 0.25;

        let volumeBubble_mat = new BABYLON.StandardMaterial("test Bubble", this.scene);
        volumeBubble_mat.emissiveColor = new BABYLON.Color3(1,0,0);
        volumeBubble_mat.alpha = 0.25;

        videoBubble_mat.backFaceCulling = false;

        this.volumeBubble.material = volumeBubble_mat;
        this.maxVolumeBubble.material = maxVolume_mat;
        this.videoBubble.material = videoBubble_mat;
        */

        this.volumeBubble.isPickable = false;
        this.maxVolumeBubble.isPickable = false;
        this.videoBubble.isPickable = false;

        this.volumeBubble.parent = this.anchor;
        this.maxVolumeBubble.parent = this.anchor;
        this.videoBubble.parent = this.anchor;

        

    }

    public startSendingPositon = () => {
        this.scene.registerBeforeRender(() => {
            this.updatePositionOnServer();
        });
    }


    destroy(){
        this.scene.unregisterBeforeRender(this.updatePositionOnServer);
    }



    

    updatePositionOnServer = () => {

       
        this.elapsedTimeSinceUpdate += this.engine.getDeltaTime();
        this.elapsedTimeSinceDataStoreRequest += this.engine.getDeltaTime();
        if(this.elapsedTimeSinceUpdate >= this.serverUpdateRate){  
            
            //console.log(this.lastPosition + " -- " + this.camera.position);

            //console.log(this.lastRotation + " -- " + this.camera.rotation);
            
            let storeData : boolean = false;
            if(this.elapsedTimeSinceDataStoreRequest > this.maxTimeBetweenDataStorage){
                storeData = true;
                this.elapsedTimeSinceDataStoreRequest = 0;
            }
            
            
            //Avoid broadcasting if we don't need to
            if(this.elapsedTimeSinceUpdate < this.maxTimeBetweenUpdates && !storeData && this.lastPosition.equals(this.camera.position) && this.lastRotation.equals(this.camera.rotation) ){
                //do nothing
            } else {
                this.lastPosition =  this.camera.position.clone()//new BABYLON.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z);
                this.lastRotation = this.camera.rotation.clone();
                this.elapsedTimeSinceUpdate = 0;
                this.socketIOController.UpdatePosition(this.lastPosition, this.lastRotation, storeData);               
            }

            
        }   
        
    }







}