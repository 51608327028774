import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';


interface Props {
    onColorSelectionComplete : (hex:string)=>void;
}

interface State {
    currentColor : number
};


export default class AvatarColor extends React.Component<Props, State> {


    static avatarColors : string[] = [
        "",
        "#ff00a6",
        "#ffbf00",
        "#15df41",
        "#ff0d00",
        "#ff00a6",
        "#9900ff",
        "#c8ff00",
        "#cccccc",
        "#ff6200",
        "#3700ff"
    ]

    state = {
        currentColor : 1,
    }

    
    static sendColorMessage(colorIndex:number){
        MessageBus.Raise("onColorChanged",AvatarColor.avatarColors[colorIndex]);
    }

    setCurrentColor(colorIndex:number){

        AvatarColor.sendColorMessage(colorIndex);

        this.setState({currentColor:colorIndex});
    }

    onButtonClick = (event:any) => {
        this.props.onColorSelectionComplete(AvatarColor.avatarColors[this.state.currentColor]);
    }
 

    render() {

        let buttonElement = this.state.currentColor > 0 ? (<Button onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}}>NEXT</Button>) :  "";
        
        return (
            
            <div className="choose-color">
                <h1 className="heading">Choose an Avatar Color</h1>
                <div className="color-wrap w-clearfix">
                    <div onClick={() => {this.setCurrentColor(1)}} className={this.state.currentColor == 1 ? "color-01 color-select" : "color-01"}></div>
                    <div onClick={() => {this.setCurrentColor(2)}} className={this.state.currentColor == 2 ? "color-02 color-select" : "color-02"}></div>
                    <div onClick={() => {this.setCurrentColor(3)}} className={this.state.currentColor == 3 ? "color-03 color-select" : "color-03"}></div>
                    <div onClick={() => {this.setCurrentColor(4)}} className={this.state.currentColor == 4 ? "color-04 color-select" : "color-04"}></div>
                    <div onClick={() => {this.setCurrentColor(5)}} className={this.state.currentColor == 5 ? "color-05 color-select" : "color-05"}></div>
                    <div onClick={() => {this.setCurrentColor(6)}} className={this.state.currentColor == 6 ? "color-06 color-select" : "color-06"}></div>
                    <div onClick={() => {this.setCurrentColor(7)}} className={this.state.currentColor == 7 ? "color-07 color-select" : "color-07"}></div>
                    <div onClick={() => {this.setCurrentColor(8)}} className={this.state.currentColor == 8 ? "color-08 color-select" : "color-08"}></div>
                    <div onClick={() => {this.setCurrentColor(9)}} className={this.state.currentColor == 9 ? "color-09 color-select" : "color-09"}></div>
                    <div onClick={() => {this.setCurrentColor(10)}} className={this.state.currentColor == 10 ? "color-10 color-select" : "color-10"}></div>
                    
                </div>
                {buttonElement}
            </div>
            
        )
    }

}












