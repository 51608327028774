import * as BABYLON from 'babylonjs';
import NSMSceneBehavior from './NSMSceneBehavior';
import {CustomLoadingScreen} from './CustomLoadingScreen';


import {Scott} from './Scott';
import {Nick} from './Nick';
import {Darby} from './Darby';
import { NSMLobbyExperienceController } from '../controllers/NSMLobbyExperienceController';
import { PBRCustomMaterial } from 'babylonjs-materials';




let nsmSceneBehaviors : NSMSceneBehavior[] = []; 

const NSMMain = (engine: BABYLON.Engine, canvas: HTMLCanvasElement) => {

    let scene: BABYLON.Scene; 

    const createNewScene = () => {
        scene = new BABYLON.Scene(engine);

        return scene;
    }

    scene = createNewScene();

    

    //let loadingScreen = new CustomLoadingScreen();
    //engine.loadingScreen = loadingScreen;
    //engine.displayLoadingUI();

    
       
    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.UniversalCamera("MainCamera", new BABYLON.Vector3(0, 2, 3.5), scene);   
    camera.minZ = 0.1; 
    camera.fov = 65 * Math.PI / 180;    
    camera.setTarget(BABYLON.Vector3.Zero()); // This targets the camera to scene origin        
    camera.attachControl(canvas, true);// This attaches the camera to the canvas
    camera.speed = 0.25;
    camera.rotation.x = -2 * Math.PI / 180;

    camera.angularSensibility *= -1;


   
   
    //Render pipeline     
    
    var pipeline = new BABYLON.DefaultRenderingPipeline(
        "defaultPipeline", // The name of the pipeline
        true, // Do you want the pipeline to use HDR texture?
        scene, // The scene instance
        [camera] // The list of cameras to be attached to
    );
    //pipeline.samples = 4;
    pipeline.fxaaEnabled = true;    
    

    

    


    //Setup asset manager
    let assetsManager : BABYLON.AssetsManager = new BABYLON.AssetsManager(scene);
    //assetsManager.useDefaultLoadingScreen = false;

    //Setup action manager
    scene.actionManager = new BABYLON.ActionManager(scene);


    let lobbyExperience = new NSMLobbyExperienceController();
    lobbyExperience.createNewScene = createNewScene;
    nsmSceneBehaviors.push(lobbyExperience);
   
    //let scott = new Scott();
    //nsmSceneBehaviors.push(scott);

    //let nick = new Nick();
    //nsmSceneBehaviors.push(nick);

    //let darby = new Darby();
    //nsmSceneBehaviors.push(darby);
    
    


    /********************************************************
    * 
    * LifeCycle Startup
    * 
    *******************************************************/   

    nsmSceneBehaviors.forEach(function (sceneBehavior) {
        sceneBehavior.Awake(engine, scene, canvas, camera);
        sceneBehavior.Start(assetsManager);
    }); 


    engine.runRenderLoop(() => {
        if (scene) {
            scene.render();
        }
    });

}


export default NSMMain;