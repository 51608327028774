import { ParticleTextureBlock } from "babylonjs";




export const LobbyDefaultConfig = {
    Server : "neondemo.inputinput.com",
    Port : 3333,
    Room : "NeonRoom3",
    MediaChannel : "StammTesting_NeonRoom3"
}


/*

export const LobbyDefaultConfig = {
    Server : "parlor.works",
    Port : 3333,
    Room : "NSMLobbyRoom2",
    MediaChannel : "StammTesting_NSMDEV4"
}


*/

