
export class MessageBus {
    
    static ListenerDictionary : Record<string,  {(data : any) : void}[]> = {};

    static AddListener(messageName : string, handler : (data : any) => void){
        let listeners : {(data : any) : void}[];
        if(MessageBus.ListenerDictionary[messageName] == null){
            listeners = [];
            MessageBus.ListenerDictionary[messageName] = listeners;
        } else {
            listeners = MessageBus.ListenerDictionary[messageName];
        }

        listeners.push(handler);

    }

    static RemoveListener(messageName : string, handler : (data : any) => void){
        let listeners : {(data : any) : void}[] = MessageBus.ListenerDictionary[messageName];
        if(listeners == null) return;

        let index = listeners.findIndex( (e) => {return handler == e;});
        if(index >= 0){
            listeners.splice(index, 1);
        }
    }

    static Raise(messageName : string, data : any){

        let listeners : {(data : any) : void}[] = MessageBus.ListenerDictionary[messageName];
        if(listeners == null) return;

        //Notify all listeners
        listeners.forEach((listener) => {
            listener(data);
        });

    }


}


/**************
MESSAGE DEFINITIONS 





 */