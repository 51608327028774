import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';


interface Props {
    onBioDataComplete : (firstName:String, lastName:string, company:string, email:string) => void;
}

interface State {        
    firstNameError : boolean
};


export default class BioIntake extends React.Component<Props, State> {
    
    firstName : any;
    lastName : any;
    company : any;
    email : any;

    state : State = {    
        firstNameError : false
    };

    
    onButtonClick = (evt : React.MouseEvent) => {

        
        if(this.firstName.value.length > 0){
            this.props.onBioDataComplete(this.firstName.value, this.lastName.value, this.company.value,this.email.value);
        } else {
            this.setState({firstNameError : true});
        }
    }

    onFirstNameChange = (event : any) => {
        MessageBus.Raise("onFirstNameChanged", this.firstName.value);
    }

    onLastNameChange = (event : any) => {
        MessageBus.Raise("onLastNameChanged", this.lastName.value);
    }

    onCompanyNameChange = (event : any) => {
        MessageBus.Raise("onCompanyChanged", this.company.value);
    }

   

    render() {

        
        return (
            
            <div className="enter-name">
                <h1 className="heading">Enter Your Information</h1>
                <form className="biodata" noValidate autoComplete="off" >

                    <TextField inputRef={el => this.firstName = el} onChange={this.onFirstNameChange} id="standard-basic" label={this.state.firstNameError ? "Please enter a first name" : "First Name"} fullWidth error={this.state.firstNameError} />
                    <TextField inputRef={el => this.lastName = el} onChange={this.onLastNameChange} id="standard-basic" label="Last Name" fullWidth />
                    <TextField inputRef={el => this.company = el} onChange={this.onCompanyNameChange} id="standard-basic" label="Company" fullWidth />
                    <TextField inputRef={el => this.email = el} id="standard-basic" label="Email" fullWidth />
                    <Button className="biodata" onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}} >
                        NEXT
                    </Button>
                </form>
            </div>
            
        )
    }

}












