import React from 'react';
import './PageLayout.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Switch } from '@material-ui/core';
import BabylonScene from './BabylonScene';
import BioIntake from './Intake/BioIntake';
import AvatarColor from './Intake/AvatarColor';
import Faces from './Intake/Faces';
import CameraForm from './Intake/CameraForm';
import { MessageBus } from '../utilities/MessageBus';
import TestContainers from './Intake/TestContainers';


interface Props {
   
}

interface State {
    
}

export default class PageLayout extends React.Component<Props, State> {
  
        
    onBioDataComplete = (firstName:String, lastName:string, company:string, email:string) => {
        let updatedState = {
            formPage :  (<AvatarColor onColorSelectionComplete={this.onColorSelectionComplete}></AvatarColor>),
            firstName : firstName,
            lastName : lastName,
            company : company,
            email : email
        }

        this.setState(updatedState);
    }

    onColorSelectionComplete = (hexColor:string) =>{
        let updatedState = {
            formPage :  (<Faces onFaceSelectionComplete={this.onFaceSelectionComplete}></Faces>),
            color : hexColor
        }
        this.setState(updatedState);
    }

    onFaceSelectionComplete = (faceIndex:number) => {
        
        let updatedState = {
            formPage :  (<CameraForm onCameraSettingComplete={this.onCameraSettingComplete}></CameraForm>),
            face : faceIndex
        }
        this.setState(updatedState);
        

        //temp code bypassing camera
        //this.setState({intakeComplete:true});
        //MessageBus.Raise("onAvatarConfigureComplete",true);
    }

    onCameraSettingComplete = () => {
        //this.props.onIntakeComplete();
        this.setState({intakeComplete:true});

        MessageBus.Raise("onAvatarConfigureComplete",true);
    }

    
    state = {
        intakeComplete : false,
        formPage :  (<BioIntake onBioDataComplete={this.onBioDataComplete}></BioIntake>),
        firstName : "",
        lastName : "",
        company : "",
        email : "",
        color : "",
        face : 0
    }

    //componentDidMount() {
        //AvatarColor.sendColorMessage(0);
    //}
  
    render() {

        let settingsView = this.state.intakeComplete ? "" : (<div className="settings">{this.state.formPage}</div>);

        let contentWrapClass = this.state.intakeComplete ? "fullScreenBabylon" : "content-wrap";
        let avatarContainerClass = this.state.intakeComplete ? "fullScreenBabylon" : "avatar";

        return(            
            <div className={contentWrapClass}>
                <div className={avatarContainerClass}>
                    <BabylonScene></BabylonScene>
                </div>
                {settingsView}
            </div>     
        )
    };
}

/*
<div className={contentWrapClass}>
                <div className={avatarContainerClass}>
                    <BabylonScene></BabylonScene>
                </div>
                {settingsView}
            </div>    



             <TestContainers />   
            */

