import * as BABYLON from 'babylonjs';
import * as BABYLONGUI from 'babylonjs-gui';
import 'babylonjs-loaders';
import { PBRCustomMaterial } from 'babylonjs-materials';
import { RemoteAvatarView } from './RemoteAvatarView';

export class RemoteAvatarInstanceFactory{
    
    avatarMeshTask : BABYLON.MeshAssetTask;
    avatarTextureTask : BABYLON.TextureAssetTask;

    templateCreated : boolean = false;

    //Template pieces
    avatarTemplateRoot : BABYLON.Mesh;
    glowTemplate : BABYLON.Mesh;
    bodyTemplate : BABYLON.Mesh;
    bodyGlowTemplate : BABYLON.Mesh;
    headTemplate : BABYLON.Mesh;
    eyeTemplate : BABYLON.Mesh;
    hairTemplate : BABYLON.Mesh;
    mouthTemplate : BABYLON.Mesh;
    webCamScreenTemplate : BABYLON.Mesh;
    textAreaTemplate : BABYLON.Mesh;

    hoverAnimation : BABYLON.Animation;

    constructor(private scene : BABYLON.Scene){
       
    }
    

    public GetInstance(scene : BABYLON.Scene, userID : string){
        //Create Instance
        let newAvatarRoot = new BABYLON.TransformNode("Avatar_" + userID, scene);
        newAvatarRoot.setAbsolutePosition(this.avatarTemplateRoot.position);

        let newAvatarScalingNode = new BABYLON.TransformNode("Avatar_Scale_" + userID, scene);
        newAvatarScalingNode.parent = newAvatarRoot;
        newAvatarScalingNode.position = new BABYLON.Vector3(0,0,0);
        newAvatarScalingNode.scaling = new BABYLON.Vector3(.55 * 1.5, .55 * 1.5, .55 * 1.5);

        let newGlow = this.glowTemplate.createInstance("Floor Glow_" + userID);
        newGlow.parent = newAvatarScalingNode;
        newGlow.scaling = new BABYLON.Vector3(.5, .5, .5);

        let newBody = this.bodyTemplate.createInstance("Body_" + userID);
        newBody.parent = newAvatarScalingNode;

        let newBodyGlow = this.bodyGlowTemplate.createInstance("Body Glow_" + userID);
        newBodyGlow.parent = newBody;

        let newHead = this.headTemplate.createInstance("Head_" + userID);
        newHead.parent = newBody;

        let newEyes = this.eyeTemplate.createInstance("Eyes_" + userID);
        newEyes.parent = newHead;

        let newHair = this.hairTemplate.createInstance("Hair_" + userID);
        newHair.parent = newHead;

        let newMouth = this.mouthTemplate.createInstance("Mouth_" + userID);
        newMouth.parent = newHead;

        let newWebcamScreen = this.webCamScreenTemplate.clone("Webcam Screen_" + userID);
        newWebcamScreen.parent = newHead;
        newWebcamScreen.rotation.y = BABYLON.Tools.ToRadians(180);
        newWebcamScreen.setEnabled(false);

        //Create webcam Material;
        const webcamMaterial = new BABYLON.PBRMaterial("webcamMaterial", this.scene);
        webcamMaterial.unlit = true;
        newWebcamScreen.material = webcamMaterial;

        let newTextArea = this.textAreaTemplate.clone("Text Area_" + userID);
        newTextArea.parent = newBody;
        newTextArea.rotation.y = BABYLON.Tools.ToRadians(180);
        newTextArea.setEnabled(true);

        //Setup text area
        let newAvatarText = BABYLONGUI.AdvancedDynamicTexture.CreateForMesh(newTextArea);

        let newTextStack = new BABYLONGUI.StackPanel();
        newAvatarText.addControl(newTextStack);

        let newNameText = new BABYLONGUI.TextBlock();
        newNameText.height = "150px";
        newTextStack.addControl(newNameText);

        let newTitleText = new BABYLONGUI.TextBlock();
        newTitleText.height = "150px";
        newTextStack.addControl(newTitleText);

        //Finalize avatar
        newAvatarRoot.setAbsolutePosition(this.avatarTemplateRoot.position);
        
        let instance = new RemoteAvatarView(newAvatarRoot, newGlow, newBody, newBodyGlow, newHead, newEyes, newHair, newMouth, newWebcamScreen, newTextArea, newNameText, newTitleText, this.hoverAnimation, scene);

        return instance;
    }

    public CreateTemplate = () : Promise<boolean> => {

        console.log("CreateTemplate CreateTemplate CreateTemplate CreateTemplate CreateTemplate CreateTemplate v");

        return new Promise((resolve,reject)=>{       

            console.log("Avatar template does not exist. Creating template.")

            let assetsManager = new BABYLON.AssetsManager(this.scene);

            this.avatarMeshTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "iPadAvatar6-1.glb");
            this.avatarTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/iPadAvatarFaces5-28.png", false, false, 3);

            assetsManager.onFinish = (tasks) => {
                let avatarTexture = this.avatarTextureTask.texture;
                avatarTexture.hasAlpha = true;
                
                let meshes = this.avatarMeshTask.loadedMeshes;
                for(let i = 0; i < meshes.length; i++) {
                    if(meshes[i].name === "__root__") {this.avatarTemplateRoot = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name === "HoverBody") {this.bodyTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name === "HoverBodyEmit") {this.bodyGlowTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name === "ScreenFrame") {this.headTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name === "AvatarFace_Eyes") {this.eyeTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name === "AvatarFace_Hair") {this.hairTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name === "AvatarFace_Mouth") {this.mouthTemplate = meshes[i] as BABYLON.Mesh;} 
                    else if(meshes[i].name === "Glow") {this.glowTemplate = meshes[i] as BABYLON.Mesh;}
                    else if(meshes[i].name === "WebCamScreen") {this.webCamScreenTemplate = meshes[i] as BABYLON.Mesh;} 
                }

                this.textAreaTemplate = BABYLON.MeshBuilder.CreatePlane("textArea", {width: 1.9, height: 1.9, sideOrientation: 2}, this.scene);
                this.textAreaTemplate.parent = this.bodyTemplate;
                this.textAreaTemplate.position = new BABYLON.Vector3(0, 1.15, 0);

                this.headTemplate.scaling.x = -1;

                this.eyeTemplate.scaling =
                this.hairTemplate.scaling =
                this.mouthTemplate.scaling = 
                new BABYLON.Vector3(1,1,1);
                
                let avatarScale = new BABYLON.Vector3(.55, .55, .55);
                this.avatarTemplateRoot.scaling = avatarScale;
                this.avatarTemplateRoot.rotationQuaternion = null;
                this.avatarTemplateRoot.rotation.y = BABYLON.Tools.ToDegrees(0);
                this.avatarTemplateRoot.setEnabled(false);
                
                //Create body and head material
                let bodyHeadMat = new BABYLON.PBRMaterial("bodyHeadMat", this.scene);
                bodyHeadMat.transparencyMode = 0;
                bodyHeadMat.albedoTexture = avatarTexture;
                bodyHeadMat.unlit = true;

                this.bodyTemplate.material?.dispose(false, true);
                this.headTemplate.material?.dispose(false, true);

                this.bodyTemplate.material =
                this.headTemplate.material =
                bodyHeadMat;
                      
                //Create UV adjustable unlit face material
                let uvAdjustableMat = new PBRCustomMaterial("uvAdjustableMat", this.scene);
                uvAdjustableMat.transparencyMode = 0;
                uvAdjustableMat.unlit = true;
                uvAdjustableMat.albedoTexture = avatarTexture;

                uvAdjustableMat.AddAttribute("uvc");
                uvAdjustableMat.Vertex_Definitions("attribute vec2 uvc;");
                uvAdjustableMat.Vertex_Before_PositionUpdated("uvUpdated += uvc;");

                this.eyeTemplate.material?.dispose(false, true);
                this.hairTemplate.material?.dispose(false, true);
                this.mouthTemplate.material?.dispose(false, true);
                this.bodyGlowTemplate.material?.dispose(false, true);

                this.eyeTemplate.material =
                this.hairTemplate.material =
                this.mouthTemplate.material =
                this.bodyGlowTemplate.material =
                uvAdjustableMat;
                
                //Create color adjustable floor glow material
                let colorAdjustableGlowMat = new BABYLON.PBRMaterial("colorAdjustableGlowMat", this.scene);
                colorAdjustableGlowMat.transparencyMode = 2;
                colorAdjustableGlowMat.unlit = true;
                colorAdjustableGlowMat.albedoTexture = avatarTexture;
                colorAdjustableGlowMat.useAlphaFromAlbedoTexture = true;
                
                this.glowTemplate.material?.dispose(false, true);
                this.glowTemplate.material = colorAdjustableGlowMat;

                this.webCamScreenTemplate.material?.dispose(false, true);
                this.webCamScreenTemplate.setEnabled(false);

                //Set Instance buffers
                this.bodyTemplate.registerInstancedBuffer("color", 4);
                this.bodyGlowTemplate.registerInstancedBuffer("color", 4);
                this.headTemplate.registerInstancedBuffer("color", 4);
                this.eyeTemplate.registerInstancedBuffer("color", 4);
                this.hairTemplate.registerInstancedBuffer("color", 4);
                this.mouthTemplate.registerInstancedBuffer("color", 4);
                this.glowTemplate.registerInstancedBuffer("color", 4);

                this.bodyTemplate.instancedBuffers.color =
                this.bodyGlowTemplate.instancedBuffers.color =
                this.headTemplate.instancedBuffers.color =
                this.eyeTemplate.instancedBuffers.color =
                this.hairTemplate.instancedBuffers.color =
                this.mouthTemplate.instancedBuffers.color =
                this.glowTemplate.instancedBuffers.color =
                new BABYLON.Color3(0.9, 0.9, 0.9);

                this.eyeTemplate.registerInstancedBuffer("uvc", 2);
                this.hairTemplate.registerInstancedBuffer("uvc", 2);
                this.mouthTemplate.registerInstancedBuffer("uvc", 2);

                this.eyeTemplate.instancedBuffers.uvc =
                this.hairTemplate.instancedBuffers.uvc =
                this.mouthTemplate.instancedBuffers.uvc = 
                new BABYLON.Vector2(1/4,0);

                console.log("Avatar template created");

                this.hoverAnimation = new BABYLON.Animation("hover", "position.y", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

                let keys = [];

                keys.push({
                    frame: 0,
                    value: 0
                });

                keys.push({
                    frame: 90,
                    value: .075
                });

                keys.push({
                    frame: 180,
                    value: 0
                });

                this.hoverAnimation.setKeys(keys);

                let easingFunction = new BABYLON.QuadraticEase();

                easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

                this.hoverAnimation.setEasingFunction(easingFunction);

                this.templateCreated = true;

                resolve(true);
            }

            assetsManager.load();

        });
    }
}