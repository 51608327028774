import 'fm.liveswitch';
import { LocalMediaController } from './LocalMediaController';


export class LocalStreamUpController{

    private audioStream : fm.liveswitch.AudioStream;
    private videoStream : fm.liveswitch.VideoStream;

    private connection : fm.liveswitch.SfuUpstreamConnection;
    private localMediaController : LocalMediaController

    constructor( private client:fm.liveswitch.Client, private channel : fm.liveswitch.Channel){

    }
    
    public connect (localMediaController : LocalMediaController, useAudio:boolean, useVideo:boolean) : fm.liveswitch.Future<boolean>{

        this.localMediaController = localMediaController;

        let promise = new fm.liveswitch.Promise<boolean>();       

        //Safety check to ensure we actual want to and can use audio / video
        if(!this.localMediaController.useAudio ||  !this.localMediaController.localMedia || !this.localMediaController.localMedia.getAudioInput() || !this.localMediaController.localMedia.getAudioInput().getName()){
            console.log("Audio Selection Mismatch");
            useAudio = false;
        }

        if(!this.localMediaController.useVideo ||  !this.localMediaController.localMedia || !this.localMediaController.localMedia.getVideoInput() || !this.localMediaController.localMedia.getVideoInput().getName()){
                        
            
            console.log("Video Selection Mismatch")
            useVideo = false;
        }


        if(useAudio){            
            if(this.localMediaController.localMedia != null){
                this.audioStream = new fm.liveswitch.AudioStream(this.localMediaController.localMedia);
            }
        }

        if(useVideo){
            if(this.localMediaController.localMedia != null){
                this.videoStream = new fm.liveswitch.VideoStream(this.localMediaController.localMedia);
            }
        }
        
        let doConnect = true;
        if(useAudio && useVideo){
            this.connection = this.channel.createSfuUpstreamConnection(this.audioStream, this.videoStream);
        } else if(useAudio){
            this.connection = this.channel.createSfuUpstreamConnection(this.audioStream);
        } else if(useVideo){
            this.connection = this.channel.createSfuUpstreamConnection(this.videoStream);
        } else {
            //We don't need to stream up. Our camera and mic is off
            console.log("No media stream. No connection necessary");
            doConnect = false;
            promise.resolve(true);
        }            

        if(doConnect){
            this.connection.open().then((result) => {
                console.log("SFU Upstream connection established");
                promise.resolve(true);
            }).fail(function(ex) {
                console.log("An error occurred setting SFU Up Stream");
                console.log(ex);
                promise.reject(ex);
            });
        }

        return promise;
    }

    public disconnect () : fm.liveswitch.Future<boolean>{
        let promise = new fm.liveswitch.Promise<boolean>();


        return promise;
    }


    public setVideoMuted(b : boolean){

    }


    public setAudioMuted(b : boolean){

    }

}