import * as BABYLON from 'babylonjs';
import { AvatarData } from '../avatars/AvatarData';
import 'babylonjs-loaders';
import { BabylonFileLoaderConfiguration, Scene, PBRMaterial, VertexBuffer, Mesh, Vector3, Material, SimplexPerlin3DBlock, Texture, VideoTexture } from 'babylonjs';
import SocketIOController, { SocketIOSubscriber } from '../scene/SocketIOController';

export class JumbotronController implements SocketIOSubscriber{
    private socketIOController : SocketIOController;
    private targetMaterial : PBRMaterial;

    private slides : Array<[string, string]> = [];

    private scene : Scene;

    private jumboTronTexture : BABYLON.Texture;
    private videoTexture : BABYLON.VideoTexture;

    constructor(socketIOController: SocketIOController, targetMaterial : BABYLON.PBRMaterial, scene : Scene)
    {
        this.socketIOController = socketIOController;
        this.socketIOController.AddListener(this);
        this.targetMaterial = targetMaterial;
        this.scene = scene;

        this.slides.push(["video", "1726134645-grad.mp4"]);
        this.slides.push(["image", "slide-01.png"]);
        this.slides.push(["image", "slide-02.png"]);

        //this.SetSlide(0);


    }

    onPlayerConnected =  () => {};
    onRemotePlayerConnected = (playerData: AvatarData) => {};
    onRemotePlayerDisconnected = (playerID: AvatarData) => {};
    onMessage = (messageName: string, message: any) => {};
    
    onGlobalMessage = (messageName: string, message: any) => {     
        /*   
        if(messageName == "jumboTronMsg"){
            this.SetSlide(message.slideNumber);
        }
        */
    }

    onVariableUpdate = (variableName: string, newValue: any) => {       
      
        if(variableName == "jumboTronMsg"){
            this.SetSlide(newValue.slideNumber);
        }
    }
    
    onPlayerPositionsUpdate = (playerData: any) => {};
    onPlayerDataUpdate = (playerData: AvatarData) => {};

    public SetSlide(slideNumber : number){
      
        if(slideNumber >= 0 && this.slides.length > slideNumber){
          

            if(this.slides[slideNumber][0] == "image"){
                

                this.jumboTronTexture = new BABYLON.Texture("./assets/textures/Jumbotron/" + this.slides[slideNumber][1], this.scene, false, true, 3, ()=> {

                    //Get reference to old material
                    let oldTexture = this.targetMaterial.albedoTexture;

                    //Change to the new texture
                    this.targetMaterial.albedoTexture = this.jumboTronTexture;

                    //Get rid of the old texture
                    if(oldTexture){
                        oldTexture.dispose();
                    }

                  
                    
                });
            }
            else if(this.slides[slideNumber][0] == "video"){


                let videoTextureSettings : BABYLON.VideoTextureSettings = {
                    loop: true,
                    autoPlay: true,
                    autoUpdateTexture: true                    
                };

                this.videoTexture = new BABYLON.VideoTexture(this.slides[slideNumber][1] ,"./assets/videos/Jumbotron/" + this.slides[slideNumber][1], this.scene, true, false, BABYLON.Texture.TRILINEAR_SAMPLINGMODE, videoTextureSettings);
                this.videoTexture.video.preload = "auto";
                //newVideoTexture.video.muted = true;
                
                //Handle the startup case and go with the video texture right away if nothing else exists
                //if(!this.videoTexture && !this.jumboTronTexture){
                //    this.targetMaterial.albedoTexture = newVideoTexture;
                // }

                //What for the promise to be finished to play
                BABYLON.VideoTexture.WhenAllReady([this.videoTexture], ()=>{
                    console.log("Ready to play video");
                    this.targetMaterial.albedoTexture = this.videoTexture;
                    this.videoTexture.video.play();

                    if(this.jumboTronTexture){
                        this.jumboTronTexture.dispose();
                    }

                    

                   
                });

                //Monitor the onplay event to add texture to the material
                
                this.videoTexture.video.onplay =(()=>{    
                    this.targetMaterial.albedoTexture = this.videoTexture;
                });
                

                //Listen for errors
                this.videoTexture.video.onerror = ((event)=>{
                    console.log("Video error");
                    console.log(event);
                });


                //Handle stubborn mobile / browsers
                this.videoTexture.onUserActionRequestedObservable.add(() => {                    
                    this.scene.onPointerDown = () => { 
                        console.log(" Bump Browser to play video");
                        this.videoTexture.video.play();
                        
                    }
                });
                
                
            }
        }
    }

    public dispose(){
        //TODO TODO any othe materials/texure to clean up ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TODO TODO TODO
    }

  

}