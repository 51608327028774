import * as React from 'react';
import * as BABYLON from 'babylonjs';
import '../scene/NSMMain';
import NSMMain from '../scene/NSMMain';
import Box from '@material-ui/core/Box';
import { PBRCustomMaterial } from 'babylonjs-materials';


interface Props {

}

interface State {

};


export default class BabylonScene extends React.Component<Props, State> {

    
    engine: BABYLON.Engine;
    canvas: HTMLCanvasElement;
    

    onResizeWindow = () => {
      
        this.resizeCanvas();
        if (this.engine) {
            this.engine.resize();
        }
    }

    componentDidMount() {
        
              
        if(this.canvas){

            

            this.engine = new BABYLON.Engine(
                this.canvas,
                true,
                undefined,
                undefined
            );

            this.engine.onResizeObservable.add(()=>{
                //console.log("RESIZERESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE RESIZE ");
            })

          
            NSMMain(this.engine, this.canvas);

            // Resize the babylon engine when the window is resized
            window.addEventListener('resize', this.onResizeWindow);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeWindow);
    }

    onCanvasLoaded = (canvas: HTMLCanvasElement) => {
       
        if (canvas !== null) {
            this.canvas = canvas;

            this.resizeCanvas();

            if(this.engine){
                this.engine.resize();
            }

        }
    }

    resizeCanvas() {
        
        if(this.canvas){
            
            var parent = this.canvas.parentElement;

            if (parent != null) {
                //this.canvas.width = parent.offsetWidth;
                //this.canvas.height = parent.offsetHeight;
            }
        }

    }

    render() {
        
        return (   
            <canvas id="renderCanvas" className="babylon" ref={this.onCanvasLoaded}/>
            
        )
    }

}

/*

<Box height="100%" width="100%">
                <canvas id="renderCanvas" ref={this.onCanvasLoaded} />
            </Box>

            */
