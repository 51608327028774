import 'fm.liveswitch';
import { timingSafeEqual } from 'crypto';
import { iLocalMediaDeviceSubscriber } from './iLocalMediaDeviceSubscriber';

export class LocalMediaController{

    public localMedia : fm.liveswitch.LocalMedia | null;
    public htmlVideoElement : HTMLVideoElement;
    public useAudio : boolean;
    public useVideo : boolean;
    public useScreenShare : boolean;

    private isStarting : boolean = false;  
    private isStarted : boolean = false;  

    listeners : iLocalMediaDeviceSubscriber[] = [];

    constructor(){

    }


    public start(useAudio : boolean, useVideo : boolean, useScreenShare : boolean = false) : fm.liveswitch.Future<LocalMediaController>{     

       
        
        let promise = new fm.liveswitch.Promise<LocalMediaController>(); 
      
        if(this.localMedia != null){
            this.stop().then(()=>{
                this.doStart(useAudio,useVideo,useScreenShare).then(()=>{
                    promise.resolve(this);
                }).fail((ex)=>{
                    promise.reject(ex);
                });
            }).fail((ex)=>{
                promise.reject(ex);
            });
        } else {
            this.doStart(useAudio,useVideo,useScreenShare).then(()=>{
                promise.resolve(this);
            }).fail((ex)=>{
                promise.reject(ex);
            });
        }

        return promise;
        
    }

    
    public stop() : fm.liveswitch.Future<boolean>{        
        
        let promise = new fm.liveswitch.Promise<boolean>();       
        
        if(this.isStarting){
          
            //we can't stop while a start is in progress            
            promise.reject(new fm.liveswitch.Exception("Unable to stop media while it is starting"));

        } else {
            
          
            if(this.localMedia){
            
                this.localMedia.stop().then(()=>{         
               
                    this.isStarted = false;
                    
                    this.localMedia?.destroy();
                    promise.resolve(true);                    
    
                }).fail((ex)=>{
                  
                    console.log("Failed to stop local media");
                    console.log(ex);
                    promise.reject(ex);
                });
            }
            
        }

        
        return promise;
    }
    

    public getVideoDevices() : fm.liveswitch.Future<fm.liveswitch.SourceInput[]>{
        let promise = new fm.liveswitch.Promise<fm.liveswitch.SourceInput[]>();

        if(!this.useVideo || !this.localMedia){
            promise.reject(new fm.liveswitch.Exception("Video not in use or local media not created."));
        }

        this.localMedia?.getVideoSourceInputs()
        .then((sources)=>{

            //Notify all subscribers
            this.listeners.forEach((listener)=>{
                listener.onVideoDeviceListUpdate(sources);
            });

            promise.resolve(sources);
        }).fail((exception)=>{            
            console.log("Failed to retrieve video sources");
            console.log(exception);
            promise.reject(exception);
        });

        return promise;
    }


    public getAudioDevices() : fm.liveswitch.Future<fm.liveswitch.SourceInput[]>{
        let promise = new fm.liveswitch.Promise<fm.liveswitch.SourceInput[]>();

        if(!this.useAudio || !this.localMedia){
            promise.reject(new fm.liveswitch.Exception("Audio not in use or local media not created"));
        }

        this.localMedia?.getAudioSourceInputs()
        .then((sources)=>{
            //Notify all subscribers
            this.listeners.forEach((listener)=>{
                listener.onAudioDeviceListUpdate(sources);
            });
            promise.resolve(sources);
        }).fail((exception)=>{            
            console.log("Failed to retrieve audio sources");
            console.log(exception);
            promise.reject(exception);
        });

        return promise;
    }


    public changeVideoSource(id:string, name:string) : fm.liveswitch.Future<boolean>{
        let promise = new fm.liveswitch.Promise<boolean>();

        if(!this.useVideo || !this.localMedia) promise.reject(new fm.liveswitch.Exception("Video not in use or local media not created"));

        this.localMedia?.changeVideoSourceInput(new fm.liveswitch.SourceInput(id, name))
        .then(()=>{
            promise.resolve(true);
        })
        .fail((ex)=>{
            console.log("Failed to change video source");
            console.log(ex);
            promise.reject(ex);
        });

        return promise;
    }


    public changeAudioSource(id:string, name:string) : fm.liveswitch.Future<boolean>{
        let promise = new fm.liveswitch.Promise<boolean>();

        if(!this.useAudio || !this.localMedia) promise.fail();

        this.localMedia?.changeAudioSourceInput(new fm.liveswitch.SourceInput(id, name))
        .then(()=>{
            promise.resolve(true);
        })
        .fail((ex)=>{
            console.log("Failed to change audio source");
            console.log(ex);
            promise.reject(ex);
        });

        return promise;
    }

    public AddListener(listener : iLocalMediaDeviceSubscriber){
        this.listeners.push(listener);
    }


    public RemoveListener(listener : iLocalMediaDeviceSubscriber){
        let index = this.listeners.findIndex( (e) => {return listener == e;});
        if(index >= 0){
            this.listeners.splice(index, 1);
        }
    }



    private doStart(useAudio : boolean, useVideo : boolean, useScreenShare : boolean = false) : fm.liveswitch.Future<LocalMediaController>{       
      
        this.useAudio = useAudio;
        this.useVideo = useVideo;
        this.useScreenShare = useScreenShare;

        let promise = new fm.liveswitch.Promise<LocalMediaController>();


        if(!this.isStarted){
            let audioConfig : any = this.useAudio;
            let videoConfig : any = this.useVideo;
    
            if( this.useAudio){         
                audioConfig = {
                    sampleSize: 16,
                    channelCount: 2,
                    echoCancellation: true
                };
            }  
    
            if(this.useVideo){         
                videoConfig = {
                    width: 320,
                    height: 240,
                    frameRate: 30
                };
            }
           
            this.localMedia = new fm.liveswitch.LocalMedia(audioConfig, videoConfig, this.useScreenShare);            
            
            this.isStarting = true;
            this.localMedia.start().then((_localMedia) => {
              
                this.isStarting = false;
                this.isStarted = true;
                this.htmlVideoElement = this.localMedia?.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;                  
    
                promise.resolve(this);            
            }).fail(function(ex) {
               
                console.log(ex.message);
                promise.reject(ex);
            });     
        } else {
            promise.reject(new fm.liveswitch.Exception("Local media is already started"));     
        }
        
        return promise;
        
    }

}