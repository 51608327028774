import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';
import styles from './Faces.module.css';

interface Props {
    onFaceSelectionComplete : (hex:number)=>void;
}

interface State {

};


export default class Faces extends React.Component<Props, State> {

    state = {
        currentFace : 0,
    }


    setCurrentFace(faceIndex:number){

        MessageBus.Raise("onFaceChanged", faceIndex);

        this.setState({currentFace:faceIndex});
    }

    onButtonClick = (event:any) => {
        this.props.onFaceSelectionComplete(this.state.currentFace);
    }

    render() {

        let buttonElement = this.state.currentFace >= 0 ? (<Button onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}}>NEXT</Button>) :  "";
        
        return (
            
            <div className="choose-face">
                <h1 className="heading">Choose a Face</h1>
                <p className={styles.subHeadP}>This face will be displayed if you should choose to turn off your webcam.</p>
                <div className="color-wrap">
                    <div onClick={() => {this.setCurrentFace(0)}} className={this.state.currentFace == 0 ? "face-01 face-select" : "face-01"}></div>
                    <div onClick={() => {this.setCurrentFace(1)}} className={this.state.currentFace == 1 ? "face-03 face-select" : "face-03"}></div>
                    <div onClick={() => {this.setCurrentFace(2)}} className={this.state.currentFace == 2 ? "face-04 face-select" : "face-04"}></div>
                    <div onClick={() => {this.setCurrentFace(3)}} className={this.state.currentFace == 3 ? "face-07 face-select" : "face-07"}></div>
                    <div onClick={() => {this.setCurrentFace(4)}} className={this.state.currentFace == 4 ? "face-08 face-select" : "face-08"}></div>
                    <div onClick={() => {this.setCurrentFace(5)}} className={this.state.currentFace == 5 ? "face-09 face-select" : "face-09"}></div>
                    <div onClick={() => {this.setCurrentFace(6)}} className={this.state.currentFace == 6 ? "face-10 face-select" : "face-10"}></div>
                    <div onClick={() => {this.setCurrentFace(7)}} className={this.state.currentFace == 7 ? "face-05 face-select" : "face-05"}></div>                
                </div>
                {buttonElement}
            </div>
            
        )
    }

}












