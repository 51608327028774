import * as BABYLON from 'babylonjs';
import * as BABYLONGUI from 'babylonjs-gui';
import {MessageBus} from '../utilities/MessageBus';
import { LocalMediaController } from '../liveSwitch/LocalMediaController';
import { AvatarData } from '../avatars/AvatarData';
import { threadId } from 'worker_threads';
import 'babylonjs-loaders';
import { BabylonFileLoaderConfiguration, Scene, PBRMaterial, VertexBuffer, Mesh, Vector3 } from 'babylonjs';
import { PBRCustomMaterial } from 'babylonjs-materials';
import { TIMEOUT, resolve } from 'dns';

export class RemoteAvatarView{
        //Instance Pieces
        private root : BABYLON.TransformNode;
        private glow : BABYLON.InstancedMesh;
        private body : BABYLON.InstancedMesh;
        private bodyGlow : BABYLON.InstancedMesh;
        private head : BABYLON.InstancedMesh;
        private eyes : BABYLON.InstancedMesh;
        private hair : BABYLON.InstancedMesh;
        private mouth : BABYLON.InstancedMesh;
        private webcamScreen : BABYLON.Mesh;
        private textArea : BABYLON.Mesh;
        private nameText : BABYLONGUI.TextBlock;
        private titleText : BABYLONGUI.TextBlock;

        private videoTexture : BABYLON.VideoTexture;

        constructor(
            root : BABYLON.TransformNode, 
            glow : BABYLON.InstancedMesh, 
            body : BABYLON.InstancedMesh, 
            bodyGlow : BABYLON.InstancedMesh, 
            head : BABYLON.InstancedMesh, 
            eyes : BABYLON.InstancedMesh, 
            hair : BABYLON.InstancedMesh, 
            mouth : BABYLON.InstancedMesh, 
            webcamScreen : BABYLON.Mesh, 
            textArea : BABYLON.Mesh, 
            nameText : BABYLONGUI.TextBlock, 
            titleText : BABYLONGUI.TextBlock,
            hoverAnimation : BABYLON.Animation,
            scene : BABYLON.Scene)
        {
            this.root = root;
            this.glow = glow;
            this.body = body;
            this.bodyGlow = bodyGlow;
            this.head = head;
            this.eyes = eyes;
            this.hair = hair;
            this.mouth = mouth;
            this.webcamScreen = webcamScreen;
            this.textArea = textArea;
            this.nameText = nameText;
            this.titleText = titleText;

            this.body.rotationQuaternion = null;
            this.head.rotationQuaternion = null;

            this.StartHoverAnimation(hoverAnimation, scene);
        }

        private timeout(ms : number){
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        private async StartHoverAnimation(hoverAnimation : BABYLON.Animation, scene : BABYLON.Scene){
            this.body.animations = [];
            this.body.animations.push(hoverAnimation);

            await this.timeout(Math.random());

            scene.beginAnimation(this.body, 0, 180, true);
        }

        public GetRoot(){return this.root;}
        public GetWebcamScreen(){return this.webcamScreen;}


        setWebViewTexture(htmlVideoElement : HTMLVideoElement, scene : BABYLON.Scene){
            this.videoTexture = new BABYLON.VideoTexture("videoTexture",htmlVideoElement, scene, false, true);
            let videoMaterial : PBRMaterial = this.webcamScreen.material as PBRMaterial;
            videoMaterial.albedoTexture = this.videoTexture;            
        }

        showWebCam(){

           
            this.webcamScreen.setEnabled(true);
            this.eyes.setEnabled(false);
            this.hair.setEnabled(false);
            this.mouth.setEnabled(false);
        }

        hideWebCam(){
            this.webcamScreen.setEnabled(false);
            this.eyes.setEnabled(true);
            this.hair.setEnabled(true);
            this.mouth.setEnabled(true);
        }

        public dispose(){
            this.root.dispose();

            //TODO TODO any othe materials/texure to clean up ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ TODO TODO TODO
        }

        public setPosition(x:number, y:number, z:number, verticalOffset:number, scene:BABYLON.Scene){
            this.root.position.x = x;
            this.root.position.y = y - verticalOffset;
            this.root.position.z = z;

            this.CheckGlowAngle(scene);
        }

        public setRotation(x:number, y:number, z:number){
            this.head.rotation.x = x;
            this.body.rotation.y = y;
            this.head.rotation.z = z;
        }

        public SetColor(hex : string){
            let regularColor = BABYLON.Color3.FromHexString(hex).toLinearSpace();
            let highValueColor = regularColor.add(new BABYLON.Color3(0.5, 0.5, 0.5));

            this.glow.instancedBuffers.color =
            this.body.instancedBuffers.color =
            this.head.instancedBuffers.color =
            regularColor;

            this.bodyGlow.instancedBuffers.color =
            this.eyes.instancedBuffers.color =
            this.hair.instancedBuffers.color =
            this.mouth.instancedBuffers.color = 
            highValueColor;

            this.nameText.color = this.titleText.color = hex;
        }

        public SetName(first : string, last : string){
            this.nameText.text = first.trim() + " " + last.trim();
            this.nameText.fontSize = Math.min(120 / this.nameText.text.length * 15, 120);
        }

        public SetTitle(title : string){
            this.titleText.text = title.trim();
            this.titleText.fontSize = Math.min(120 / this.titleText.text.length * 15, 80);
        }

        public SetFace(faceIndex : number){
            let uvc = new BABYLON.Vector2(0,0);

            switch(faceIndex)
            {
                case 0:
                    uvc = new BABYLON.Vector2(0,0);
                    break;
                case 1:
                    uvc = new BABYLON.Vector2(1/4, 0);
                    break;
                case 2:
                    uvc = new BABYLON.Vector2(2/4, 0);
                    break;
                case 3:
                    uvc = new BABYLON.Vector2(3/4, 0);
                    break;
                case 4:
                    uvc = new BABYLON.Vector2(0, 1/4);
                    break;
                case 5:
                    uvc = new BABYLON.Vector2(1/4, 1/4);
                    break;
                case 6:
                    uvc = new BABYLON.Vector2(2/4, 1/4);
                    break;
                case 7:
                    uvc = new BABYLON.Vector2(3/4, 1/4);
                    break;
                default:
                    uvc = new BABYLON.Vector2(0, 0);
                    break;
            }

            this.eyes.instancedBuffers.uvc =
            this.mouth.instancedBuffers.uvc =
            this.hair.instancedBuffers.uvc =
            uvc;
        }

        private CheckGlowAngle(scene : BABYLON.Scene){
            let down = new BABYLON.Vector3(0, -1, 0);
            
            let direction = down.subtract(this.root.absolutePosition);
            direction = BABYLON.Vector3.Normalize(down);

            let ray = new BABYLON.Ray(this.root.absolutePosition, direction, 10);

            var hit = scene.pickWithRay(ray);

            let hitAngle;

            if(hit != null){
                hitAngle = hit.getNormal(true);
            }

            if(hitAngle != null){
                this.glow.alignWithNormal(hitAngle);
            }
        }
}