import * as BABYLON from 'babylonjs';
import { iSocketIOSubscriber } from '../webSockets/iSocketIOSubscriber';
import SocketIOController from './SocketIOController';
import { SocketIOSubscriber } from './SocketIOController';
import { LocalMediaController } from '../liveSwitch/LocalMediaController';
import { LiveSwitchController } from '../liveSwitch/LiveSwitchController';
import { LobbyStage } from './LobbyStage';
import { LocalAvatarController } from '../controllers/LocalAvatarController';
import { AvatarData } from '../avatars/AvatarData';
import { RemoteAvatarController } from '../controllers/RemoteAvatarController';


export class LobbyScene implements SocketIOSubscriber {

    private lobbyStage: LobbyStage;

    private localAvatarController: LocalAvatarController;
    private remoteAvatarController : RemoteAvatarController;

    constructor(private engine: BABYLON.Engine, private scene: BABYLON.Scene, private camera: BABYLON.UniversalCamera, private socketIOController: SocketIOController, private liveSwitchController: LiveSwitchController, private avatarData : AvatarData) {

       
        //Add scenery
        this.lobbyStage = new LobbyStage(this.engine, this.scene, this.camera, socketIOController);
        this.lobbyStage.load().then(()=>{            
            
            //Get my avatar up and running
            this.localAvatarController = new LocalAvatarController(this.engine, this.scene, this.camera, this.socketIOController);

            //Get the remote controller running
            this.remoteAvatarController = new RemoteAvatarController(this.engine, this.scene, this.camera, this.socketIOController, this.liveSwitchController, this.localAvatarController, avatarData.userID);
            this.remoteAvatarController.load();

            //Join the game on the socket
            this.socketIOController.Connect(avatarData);
            
            //Star Video Streams and Feeds
            this.liveSwitchController.connect(avatarData.micEnabled, avatarData.cameraEnabled).then((result)=>{
                
                this.remoteAvatarController.subscribeLiveswitchController();
                

            }).fail((ex)=>{
                console.log("Failed to connect to Liveswitch");
                console.log(ex)
            });           

        });        

    }


   


    onPlayerConnected = () => {

        //Tell the local avatar its ok to start broadcasting its position
        this.localAvatarController.startSendingPositon();

        console.log("onPlayerConnected");

    };

    onRemotePlayerConnected = (avatarData: AvatarData) => {
        console.log("onRemotePlayerConnected");
        console.log(avatarData);
    };

    onPlayerDataUpdate = (playerData: AvatarData) => {
        
    };

    onRemotePlayerDisconnected = (playerID: AvatarData) => {

    };

    onMessage = (messageName: string, message: string) => {

    };

    onGlobalMessage = (messageName: string, message: string) => {

    };

    
    onVariableUpdate = (variableName: string, newValue: any) => {    

    };


    onPlayerPositionsUpdate = (playerData: any) => {
        //console.log("onRemotePlayerConnected");
        //console.log(playerData);
    };

}